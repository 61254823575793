@import 'azure-maps-control/dist/atlas.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes tracking {
  0% {
    width: 24px;
    height: 24px;
  }
  50% {
    width: 40px;
    height: 40px;
  }
  100% {
    width: 24px;
    height: 24px;
  }
}

@keyframes selectedTracking {
  0% {
    width: 36px;
    height: 36px;
  }
  50% {
    width: 62px;
    height: 62px;
  }
  100% {
    width: 36px;
    height: 36px;
  }
}
